<template>
  <div>
    <div class="title">
      <div class="title_1" @click="goback">
        <img src="../../assets/zjt.png" alt="" />
        返回
      </div>
      <div class="title_2">考试详细</div>
    </div>
    <div class="war">
      <div class="body">
        <div class="total">总分:{{ row.score || 0 }}分</div>
        <div class="f-img">
          <img :src="row.status == 1 ? icon[0] : icon[1]" class="img" alt="" />
        </div>
      </div>
      <div class="center">
        <div class="tit">成绩分析</div>
        <div class="text">
          <span
            >共{{ row.total || 0 }}题，答对{{ row.correct || 0 }}题，正确率{{
              row.correct_rate
            }}</span
          >
        </div>
      </div>
      <div class="information">
        <div class="title">测试信息</div>
        <h3>{{ details.title }}</h3>
        <div class="i">
          总题数： <span>{{ row.total }}</span>
        </div>
        <div class="i">
          总&nbsp;&nbsp;&nbsp;分： <span>{{ $route.query.total_score }}</span>
        </div>
        <div class="i">
          合格分： <span>{{ $route.query.pass_score }}</span>
        </div>
      </div>
      <div class="userinfo">
        <div class="title">学员信息</div>
        <div class="item">
          <img :src="userInfo.pic" alt="" />
          <div>
            <div class="t">
              账号：<span>{{ userInfo.username }}</span>
            </div>
            <div class="b">
              姓名：<span>{{ userInfo.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="answer">答题明细</div>
      <div v-if="list.length > 0">
        <div class="row" v-for="(item, index) in list" :key="item.id">
          <div class="title">
            <div v-if="item.subject.type == 1">单选题</div>
            <div v-if="item.subject.type == 2">判断题</div>
            <div v-if="item.subject.type == 3">多选题</div>
          </div>
          <div class="subject">
            {{ index + 1 }}. <span v-html="item.subject.subject"></span>
          </div>
          <div class="ul">
            <div
              class="li"
              v-for="(item1, index) in item.subject.answers"
              :key="index"
            >
              <span v-html="item1.answer"></span>
            </div>
          </div>
          <div class="dui">
            <div>
              <img
                style="margin-right: 5px"
                v-if="item.is_correct == 1"
                src="../../assets/dui.png"
                alt=""
              />
              <img
                style="margin-right: 5px"
                v-if="item.is_correct == 2"
                src="../../assets/cuo.png"
                alt=""
              />考生答案：{{ item.student_choice || "考生未选择" }}
            </div>
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;正确答案：{{ item.subject.correct }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="tac">—— 暂无题目 ——</div>
      <img
        v-show="loading"
        class="loading"
        src="../../assets/icon_loading.png"
        alt=""
      />
      <div style="height: 100px"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import rank1 from "@/assets/rank1.png";
import rank2 from "@/assets/rank2.png";
import result1 from "@/assets/result1.png";
import result2 from "@/assets/result2.png";
import result3 from "@/assets/result3.png";
import result4 from "@/assets/result4.png";
export default {
  data() {
    return {
      row: {
        ecount: "0",
        rcount: "0",
        totalscore: "0",
        status: "1",
      },
      loading: false,
      list: [],
      pageSize: 10,
      page: 1,
      icon: [rank1, rank2, result1, result2, result3, result4],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      classid: (state) => state.classid,
      details: (state) => state.details,
    }),
  },
  methods: {
    ...mapActions(["getreturnTestResults_x", "getreturnSubjectDetail_x"]),
    // 返回上一页
    goback() {
      this.$router.go(-1);
    },
    // 列表数据
    getlist() {
      let that = this;
      setTimeout(() => {
        that
          .getreturnSubjectDetail_x({
            class_id: that.classid, //班级di
            student_id: that.userInfo.id, //学员id
            record_id: that.$route.query.id, //记录id
            page: that.page,
            pageSize: that.pageSize,
          })
          .then((res) => {
            if (res.errorCode == 399) {
              this.loading = false;
            }
            if (res.successCode == 200) {
              that.list = that.list.concat(res.responseBody.list);
              that.page++;
              that.getlist();
            }
          });
      }, 500);
    },
  },
  mounted() {
    let that = this;

    this.getreturnTestResults_x({
      record_id: this.$route.query.id, //考试记录id
      class_id: this.classid, //班级id
      student_id: this.userInfo.id, //学员id
    }).then((res) => {
      this.row = res.responseBody;
    });
    this.loading = true;
    this.getreturnSubjectDetail_x({
      class_id: this.classid, //班级di
      student_id: this.userInfo.id, //学员id
      record_id: this.$route.query.id, //记录id
      page: this.page,
      pageSize: this.pageSize,
    }).then((res) => {
      if (res.errorCode == 399) {
        this.loading = false;
      }
      if (res.successCode == 200) {
        this.list = res.responseBody.list;

        this.page++;
        this.getlist();
      }
    });
  },
};
</script>

<style lang='scss' scoped>
.loading {
  width: 50px;
  height: 50px;
  display: block;
  margin: 50px auto;
}
.title {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  height: 45px;
  .title_1 {
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #676767;
    img {
      margin-right: 5px;
      height: 20px;
      vertical-align: middle;
    }
  }
  .title_2 {
    margin-left: 24px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
}
.body {
  width: 100%;
  background: #fff;
  height: auto;
  .total {
    font-size: 14px;
    color: #fe9b1c;
    text-align: center;
    line-height: 60px;
  }
  .f-img {
    width: 155px;
    height: 126px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
}
.center {
  border-top: 12px solid #f6f5f5;
  margin-top: 12px;
  background: #fff;
  padding-top: 10px;
  .tit {
    padding: 10px 20px;
    line-height: 14px;
    font-size: 14px;
    color: #fe9b1c;
  }
  .text {
    margin-bottom: 20px;
    font-size: 12px;
    color: #999;
    line-height: 24px;
    padding: 0 20px;
  }
}
.information {
  .title {
    font-size: 14px;
    color: #fe9b1c;
    height: 45px;
    line-height: 45px;
    left: 45px;
    padding-left: 20px;
    background-color: #f6f5f5;
  }
  h3 {
    margin: 15px 0;
    margin-left: 20px;

    font-weight: normal;
  }
  .i {
    margin: 15px 0;
    margin-left: 20px;
    color: #b6b6b6;
    font-size: 12px;
    span {
      color: #000;
    }
  }
}
.userinfo {
  .title {
    font-size: 14px;
    color: #fe9b1c;
    height: 45px;
    line-height: 45px;
    left: 45px;
    padding-left: 20px;
    background-color: #f6f5f5;
  }
  .item {
    display: flex;
    align-items: center;
    height: 80px;
    img {
      margin-left: 30px;
      margin-right: 30px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .t {
      color: #aeaeae;
      margin-bottom: 15px;
      font-size: 12px;
    }
    .b {
      color: #aeaeae;
      font-size: 12px;
    }
    span {
      color: #000;
    }
  }
}
.answer {
  color: #fe9b1c;
  padding-left: 20px;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  background-color: #f6f5f5;
}
.row {
  .title {
    height: 45px;
    line-height: 45px;
    padding-left: 20px;
    font-size: 18px;
    color: #1a45ff;
    border-bottom: 1px solid #d5d5d5;
    div {
      position: relative;
      display: inline-block;
      &::before {
        position: absolute;
        content: "";
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: #1a45ff;
      }
    }
  }
  .subject {
    margin-top: 10px;
    font-size: 14px;
    padding: 0 20px;
  }
  .ul {
    margin-top: 15px;
    box-sizing: border-box;
    padding: 0 20px;
    .li {
      margin-bottom: 10px;
      background-color: #f6f5f5;
      padding: 15px 20px;
      color: #adadad;
      img {
        width: 100%;
      }
    }
  }
  .dui {
    padding: 10px 20px;
    box-sizing: border-box;
    border-left: 2px solid #c6d2ff;
    height: 60px;
    background-color: #e8edff;
    font-size: 14px;
    div {
      margin-bottom: 5px;
    }
    img {
      vertical-align: middle;
      margin-right: 10px;
      width: 15px;
    }
  }
}
.tac {
  text-align: center;
  color: #ccc;
  margin: 100px auto;
}
</style>